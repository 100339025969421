import Logo from "../../assets/FooterLogo.svg";

import {
  StyledFooter,
  StyledLinkContainer,
  StyledFooterlink,
  StyledFooterDot,
  StyledLogo,
} from "./styles";

const Footer = ({ showContent }) => {
  return (
    <StyledFooter showContent={showContent}>
      <StyledLinkContainer>
        <StyledFooterlink href="https://andopen.co/" target="blank">
          <StyledLogo src={Logo} alt="logo" />
          Powered by &Open
        </StyledFooterlink>
        <StyledFooterDot>·</StyledFooterDot>
        <StyledFooterlink href="https://andopen.co/privacy" target="blank">
          Privacy policy
        </StyledFooterlink>
        {/* <StyledFooterDot>·</StyledFooterDot>
        <StyledFooterlink
          href="https://www.airbnb.com/help/article/1635"
          target="blank"
        >
          What is AirbnbLove
        </StyledFooterlink> */}
      </StyledLinkContainer>
    </StyledFooter>
  );
};

export default Footer;
