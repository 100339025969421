import { useEffect } from "react";
import { gsap } from "gsap";

const TextAnimation = ({ showContent }) => {
  useEffect(() => {
    gsap.fromTo(
      ".animate",
      {
        opacity: 0,
        y: 10,
      },
      {
        stagger: 0.2,
        opacity: 1,
        duration: 0.5,
        y: 0,
        ease: "linear",
      }
    );
  }, [showContent]);
};

export default TextAnimation;
