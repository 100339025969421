import { useState, useEffect } from "react";
import Gsap, { gsap } from "gsap";

import Header from "./components/Header";

import {
  GlobalStyle,
  StyledContainer,
  StyledTitleContainer,
  StyledMarginTopLarge,
} from "./styles";
import { GlobalFonts } from "./fonts/fonts";
import Landing from "./components/Landing";
import Form from "./components/Form";
import Image from "./components/Image";
import Footer from "./components/Footer";
import Success from "./components/Success";
import TextAnimation from "./components/TextAnimation";

const App = () => {
  const [showContent, setShowContent] = useState("landing");
  const containerContent = (value) => {
    setShowContent(value);
  };

  return (
    <>
      <TextAnimation showContent={showContent} />
      <GlobalStyle />
      <GlobalFonts />
      <div className="App">
        <Header containerContent={containerContent} />
        <StyledMarginTopLarge />
        <StyledContainer>
          <StyledTitleContainer>
            {(showContent === "landing" && (
              <Landing containerContent={containerContent} />
            )) ||
              (showContent === "form" && (
                <Form containerContent={containerContent} />
              )) ||
              (showContent === "success" && <Success />)}
          </StyledTitleContainer>
          <Image />
        </StyledContainer>
        <Footer showContent={showContent}/>
      </div>
    </>
  );
};

export default App;
