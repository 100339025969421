import Logo from "../../assets/logo.svg";

import { StyledLogo, StyledHeader } from "./styles";

const Header = ({ containerContent }) => {
  return (
    <StyledHeader>
      <StyledLogo
        src={Logo}
        alt="logo"
        onClick={() => containerContent("landing")}
      ></StyledLogo>
    </StyledHeader>
  );
};

export default Header;
