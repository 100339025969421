import { gsap } from "gsap";
import { StyledButton } from "./styles";

const Button = ({ text, marginTop, containerContent }) => {
  return (
    <StyledButton
      className="animate"
      onClick={containerContent && (() => containerContent("form"))}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
