import styled from "styled-components";

export const StyledButton = styled.button`
  background: linear-gradient(to right, #e61e4d 0%, #e31c5f 50%, #d70466 100%);
  padding: 16px 40px;
  border-radius: 8px;
  border: none;
  font-family: CerealBold;
  color: #fff;
  /* transition: 3s; */
  cursor: pointer;

  &:hover {
    background: radial-gradient(
      circle at center,
      #ff385c 0%,
      #e61e4d 27.5%,
      #e31c5f 40%,
      #d70466 57.5%,
      #bd1e59 75%,
      #bd1e59 100%
    );
  }
`;
