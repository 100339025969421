import { createGlobalStyle } from "styled-components";

import CerealReg from "../fonts/Cereal_W_Rg.woff2";
import CerealBold from "../fonts/Cereal_W_Bd.woff2";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'CerealReg';
      src: url(${CerealReg});
    };

    @font-face {
    font-family: 'CerealBold';
      src: url(${CerealBold});
    }
`;
