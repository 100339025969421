import { useEffect } from "react";
import { gsap } from "gsap";
import House from "../../assets/home.mp4";
import { StyledImage } from "./styles";

const Image = () => {
  useEffect(() => {
    gsap.fromTo(
      ".house",
      {
        opacity: 0,
        y: 20,
      },
      {
        delay: 0.5,
        opacity: 1,
        duration: 1,
        y: 0,
        ease: "linear",
      }
    );
  }, []);

  return (
    <StyledImage className="house" autoPlay muted={true} loop>
      <source src={House} type="video/mp4" />
    </StyledImage>
  );
};

export default Image;
