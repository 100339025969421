import Button from "../Button";

import { StyledH1, StyledP, StyledMarginTopMedium } from "../../styles";

const Landing = ({ containerContent }) => {
  return (
    <>
      <StyledH1 className="animate">Thank you for hosting. </StyledH1>
      <StyledMarginTopMedium />
      <StyledP className="animate">
        It’s a tradition at Airbnb for employees to stay together when
        gathering. This is a great way to deepen connections. Thank you for
        opening your home to colleagues who are coming into town!
      </StyledP>
      <StyledMarginTopMedium />
      <Button
        className="animate"
        text="Get my gift"
        containerContent={containerContent}
      ></Button>
    </>
  );
};

export default Landing;
