import styled from "styled-components";

export const StyledFooter = styled.footer`
  padding: 40px 80px;
  width: 100%;
  position: ${(props) => (props.showContent === "form" ? "relative" : "fixed")};
  margin-top: ${(props) => (props.showContent === "form" ? "49px" : 0)};
  bottom: 0;
  left: 0;

  @media (max-width: 1000px) {
    position: relative;
  }

  @media (max-width: 600px) {
    padding: 24px 40px;
    margin-top: 64px;
  }
`;

export const StyledLogo = styled.img`
  max-width: 50%;
  height: 12px;
  margin-right: 0.4rem;
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledFooterlink = styled.a`
  font-size: 12px;
`;

export const StyledFooterDot = styled.span`
  font-size: 12px;
  padding: 0 0.4rem;
`;
