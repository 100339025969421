import styled from "styled-components";

export const StyledLogo = styled.img`
  width: 120px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 80px;
  }
`;

export const StyledHeader = styled.header`
  padding: 40px 80px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 24px 40px;
  }

  @media (max-width: 390px) {
    padding: 24px 24px;
  }
`;
