import styled from "styled-components";

export const StyledImage = styled.video`
  max-width: 70%;

  @media (max-width: 1440px) {
    max-width: 50%;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    margin-top: 32px;
  }
`;
