import Button from "../Button";

import { StyledH1, StyledP, StyledMarginTopMedium } from "../../styles";

const Success = ({ containerContent }) => {
  return (
    <>
      <StyledH1 className="animate">Your gift is on its way</StyledH1>
      <StyledMarginTopMedium />
      <StyledP className="animate">
        Thank you so much for your hospitality, we hope you enjoy your gift!
      </StyledP>
      <StyledMarginTopMedium />
      <a href="https://www.airbnb.com">
        <Button
          text="Back to airbnb.com"
          containerContent={containerContent}
        ></Button>
      </a>
    </>
  );
};

export default Success;
